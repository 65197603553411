import React from 'react';
import './VerticalSlider.css'; // Import the CSS for styling

const VerticalSlider = ({ min, max, value, onChange, height }) => {
    return (
        <div className='slider-container' >
        <input
            type="range"
            min={min}
            max={max}
            value={value}
            onChange={onChange}
            className='vertical-slider'
            style={{ height: '100%', writingMode: 'tb-lr' }}
        />
        </div>
    );
};

export default VerticalSlider;
