import { doc, setDoc, onSnapshot, getDoc } from 'firebase/firestore';
import React, { createContext, useState, useEffect, useContext } from 'react';
import { db } from '../config/firebase-config';
import { UserContext } from './UserContext';
import { DEFAULT_USER_SETTINGS } from '../hooks/default_user_settings';

// Create a Context for the user data
export const UserSettingsContext = createContext(null);

// Create a Provider Component
export const UserSettingsProvider = ({ children }) => {
    const { userInfo } = useContext(UserContext);
    const [userSettings, setUserSettings] = useState(DEFAULT_USER_SETTINGS);

    const updateUserSettings = async (userIdParam, newSettings) => {
        const uid = userIdParam || userInfo.userId;
        try {
            if (uid) {
                const userSettingsDocRef = doc(db, "user-configs", uid);
                await setDoc(userSettingsDocRef, newSettings, {merge: true});
                setUserSettings(prevSettings => ({ ...prevSettings, ...newSettings }));
            }
        } catch (err) {
            console.error("Error updating user settings in Firebase: ", err);
        }
    };

    useEffect(() => {
        let unsubscribe = () => {}; // No-op function to start
      
        if (userInfo && userInfo.userId) {
            const userSettingsDocRef = doc(db, "user-configs", userInfo.userId);
      
            unsubscribe = onSnapshot(userSettingsDocRef, (doc) => {
                if (doc.exists()) {
                    setUserSettings(doc.data());
                } else {
                    // Initialize with default settings if the document doesn't exist
                    setUserSettings(DEFAULT_USER_SETTINGS);
                    setDoc(userSettingsDocRef, DEFAULT_USER_SETTINGS);
                }
            }, (err) => {
                console.error("Firebase subscription error: ", err);
            });
        } else {
            // Reset to default settings when there's no user
            setUserSettings(DEFAULT_USER_SETTINGS);
        }
      
        return () => unsubscribe(); // Cleanup subscription on unmount or user change
      }, [userInfo?.userId]);

    const value = { userSettings, updateUserSettings };

    return (
        <UserSettingsContext.Provider value={ value }>
            {children}
        </UserSettingsContext.Provider>
    );
};
