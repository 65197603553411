import React, { useState, useEffect, useContext, useRef } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, /*Tooltip,*/ Legend, ResponsiveContainer, ReferenceLine } from 'recharts';
import { UserContext } from '../../context/UserContext.js';
import { UserSettingsContext } from '../../context/UserSettingsContext.js';
import { CONSTANTS } from '../../hooks/constants.js';
import "../../App.css";
import VerticalSlider from '../vertical-slider/VerticalSlider.js';
import ExtraInfoWidget from '../extra-info-widget/ExtraInfoWidget.js';

const MAX_DATA_POINTS = 1000;

const Plot = () => {

    // hooks
    const { userInfo } = useContext(UserContext);
    const { userSettings } = useContext(UserSettingsContext);
    const [depth, setDepth] = useState(0); // directly linked to depth
    const [isPlaying, setIsPlaying] = useState(false);
    const [stepNumber, setStepNumber] = useState(0);
    const [diveTime, setDiveTime] = useState(0); // simulated dive time
    const [chartDataInitialized, setChartDataInitialized] = useState(false);
    const [chartData, setChartData] = useState([]);
    const [maxGradientFactor, setMaxGradientFactor] = useState(0);
    const [diveMaxGradientFactor, setDiveMaxGradientFactor] = useState(0);
    const [dangerZone, setDangerZone] = useState(false);

    const extraInfo = {
        depth: depth,
        maxTissuePressure: 0,
        maxGradientFactor: maxGradientFactor,
        diveMaxGradientFactor: diveMaxGradientFactor,
        dangerZone: dangerZone
    }


    const getDefaultChartData = () => {
        const saturatedTissuePressureNitrogen = userSettings.gasPercentNitrogen / 100;
        const saturatedTissuePressureHelium = userSettings.gasPercentHelium / 100;

        const compartments = {};
        for (let i = 1; i<=16; i++) {
            compartments[`c${i}`] = {
                nitrogenLine: saturatedTissuePressureNitrogen,
                heliumLine: saturatedTissuePressureHelium,
                mValueLineNitrogen: 0,
                mValueLineHelium: 0
            };
        }
        return [{
            diveTime: 0,
            depthLine: 0,
            ambientPressureLine: 1,
            controllingMValueLine: 0,
            ...compartments,
        }];

    }

    const handleDepthChange = (event) => {
        const newDepth = event.target.value*-1;
        setDepth(newDepth);
    }

    const handleTogglePlay = () => {
        setIsPlaying(!isPlaying);
    }
    
    const handleResetGraph = () => {
        setChartData(getDefaultChartData);
        setDiveTime(0);
        setDepth(0);
        setMaxGradientFactor(0);
        setDiveMaxGradientFactor(0);
    }

    const getLeadingMValueLine = (dataPoint) => {
        let maxMValue = 0;
        for (const compartment in dataPoint) {
            // Make sure this is one of the compartments and not a different property
            if (compartment.startsWith('c') && 
                dataPoint[compartment].mValueLineNitrogen !== undefined &&
                dataPoint[compartment].mValueLineHelium !== undefined)
                {
                // Update maxMValue if the current compartment's mValueLine is greater
                maxMValue = Math.max(
                    maxMValue,
                    dataPoint[compartment]?.mValueLineNitrogen,
                    dataPoint[compartment]?.mValueLineHelium
                );
            }
        }
        return maxMValue;
    }

    const getNextMValue = (amb_pressure, A, B) => {
        // this function returns the maximum tolerated tissue pressure for the given ambient pressure
        const m = (amb_pressure / B) + A;
        // return m > 1 ? m : 0; //
        return m;
    }

    const getNextMinToleratedAmbPressure = (tissue_pressure, A, B) => { // get next tolerated ambient pressure
        // this function returns the MINIMUM AMBIENT pressure that the given tissue can tolerate
        const minP = (tissue_pressure - A) * B;
        return minP > 1 ? minP : 0; // ignore about min (return 0) if it's <= 1 ATM because you can safely surface
        // return m;
    }

    const getGradientFactor = (tissue_pressure, mValue, amb_pressure) => {
        if (tissue_pressure > amb_pressure) {
            const gf = (tissue_pressure - amb_pressure) / (mValue - amb_pressure);
            return gf*100;
        } else return 0;
    }

    const getNextTissuePressureNitrogen = (
        current_pressure,
        amb_pressure,
        half_life
    ) => {
        half_life = half_life * 60;
        const nitrogen_frac = userSettings.gasPercentNitrogen / 100;
        const simulatedTimeStep = userSettings.simulatedTimeStep;
        const gas_pressure = nitrogen_frac * amb_pressure;
        const p = current_pressure +
            (gas_pressure - current_pressure) *
            (1 - (1/2) ** (simulatedTimeStep/half_life));
        return p;
    }

    const getNextTissuePressureHelium = (
        current_pressure,
        amb_pressure,
        half_life
    ) => {
        half_life = half_life * 60;
        const helium_frac = userSettings.gasPercentHelium / 100;
        const simulatedTimeStep = userSettings.simulatedTimeStep;
        const gas_pressure = helium_frac * amb_pressure;
        const p = current_pressure +
            (gas_pressure - current_pressure) *
            (1 - (1/2) ** (simulatedTimeStep/half_life));
        return p;
    }

    useEffect(() => {
        if (Object.keys(userSettings).length > 0 && !chartDataInitialized) {
            setChartData(getDefaultChartData);
            setChartDataInitialized(true);
        }
    }, [userSettings]); // once userSettings has > 0 keys, we're loaded

    // effect to ONLY increment the step number
    useEffect(() => {
        if (isPlaying && chartDataInitialized){
            const interval = setInterval(() => {
                setStepNumber(stepNumber => stepNumber + 1);
            }, userSettings.chartUpdateIntervalMS);
            return () => clearInterval(interval);
        }

    }, [isPlaying, userSettings.chartUpdateIntervalMS, chartDataInitialized]);

    // effect to update the plot with each new step
    useEffect(() => {
        if (chartDataInitialized){
            if (Object.keys(userSettings).length === 0 || Object.keys(CONSTANTS).length === 0) {
                return;
            }
            const newDepth = depth;
            const newAmbientPressure = depth / 33 + 1;
            const newDiveTime = diveTime + userSettings.simulatedTimeStep;
            const oldDataPoint = chartData.length >=1 && chartData[chartData.length-1]; // get the latest data point
            
            setDiveTime(newDiveTime);

            // new data point for the chartData
            const newDataPoint = {
                diveTime: newDiveTime,
                depthLine: parseFloat(newDepth),
                ambientPressureLine: newAmbientPressure,
                leadingMValueLine: getLeadingMValueLine(oldDataPoint)
            };

            let runningMaxGradientFactor = 0;

            // loop over all {16} compartments 
            for (let i=1; i<= 16; i++) {
                const compartment = `c${i}`;
                const nitrogenCompartment = CONSTANTS.nitrogen[compartment];
                const heliumCompartment = CONSTANTS.helium[compartment];
                const oldNitrogenLine = oldDataPoint[compartment]?.nitrogenLine;
                const oldHeliumLine = oldDataPoint[compartment]?.heliumLine;
                
                // get the new nitrogen pressure
                const nitrogenPoint = getNextTissuePressureNitrogen(
                    oldNitrogenLine,
                    newAmbientPressure,
                    nitrogenCompartment.half_life // Nitrogen compartment 1 half life
                );

                // get the new m value
                const mValuePointNitrogen = getNextMinToleratedAmbPressure(
                    oldNitrogenLine,
                    nitrogenCompartment.a,
                    nitrogenCompartment.b
                );

                // get the new helium pressure
                const heliumPoint = getNextTissuePressureHelium(
                    oldHeliumLine,
                    newAmbientPressure,
                    heliumCompartment.half_life
                );

                const mValuePointHelium = getNextMinToleratedAmbPressure(
                    oldHeliumLine,
                    heliumCompartment.a,
                    heliumCompartment.b
                );

                const heliumGradientFactor = getGradientFactor(
                    heliumPoint,
                    // mValuePointHelium,
                    getNextMValue(newAmbientPressure, heliumCompartment.a, heliumCompartment.b),
                    // newAmbientPressure/heliumCompartment.b + heliumCompartment.a, // get the theoretical m-value for this DEPTH
                    newAmbientPressure
                );

                // NOTE: tissue pressure needs to be greater than PARTIAL PRESSURE of gas

                const nitrogenGradientFactor = getGradientFactor(
                    nitrogenPoint,
                    // mValuePointNitrogen,
                    // newAmbientPressure/nitrogenCompartment.b + nitrogenCompartment.a,
                    getNextMValue(newAmbientPressure, nitrogenCompartment.a, nitrogenCompartment.b),
                    newAmbientPressure
                );

                runningMaxGradientFactor = Math.max(
                    runningMaxGradientFactor,
                    heliumGradientFactor,
                    nitrogenGradientFactor
                );


                // add this {compartment} to newDataPoint and continue the loop
                newDataPoint[compartment] = {
                    nitrogenLine: nitrogenPoint,
                    mValueLineNitrogen: mValuePointNitrogen,
                    heliumLine: heliumPoint,
                    mValueLineHelium: mValuePointHelium
                }
            }

            setMaxGradientFactor(runningMaxGradientFactor);
            setDiveMaxGradientFactor(Math.max(diveMaxGradientFactor, runningMaxGradientFactor));
            setDangerZone(runningMaxGradientFactor > 100 ? true : false);

            // write the new data apoint
            setChartData(currentData => {
                let newData = [...currentData, newDataPoint];

                if (newData.length > MAX_DATA_POINTS) {
                    newData = newData.slice(newData.length - MAX_DATA_POINTS);
                }
                // return [...newData, newDataPoint];
                return newData;
            });
        }
    }, [stepNumber]);


    return (
        <div className='game-pane'>
            <ExtraInfoWidget extraInfo={extraInfo}/>
            <div className='live-plot'>
                <ResponsiveContainer width="100%" aspect={1.8}>
                <LineChart data={chartData}>

                    <CartesianGrid strokeDasharray="3 3" />
                    {/* <XAxis 
                        dataKey="diveTime"
                        tickFormatter={(tick) => Math.floor(tick/60)}
                    /> */}
                    <XAxis
                        dataKey="diveTime"
                        tickFormatter={(unixTime) => {
                            // Convert your time value to a date object if it's not already one
                            const date = new Date(unixTime * 1000); // assuming diveTime is in seconds
                            return date.toISOString().substr(11, 8); // extracts hh:mm:ss part
                        }}
                        interval="preserveStartEnd" // This will ensure the first and last ticks are always rendered
                        // You might want to use a number or 'preserveStart' if 'preserveStartEnd' doesn't work as expected
                        type="number" // Make sure to set the type to number if diveTime is a number
                        domain={['dataMin', 'dataMax']} // Adjust domain if needed to ensure ticks are aligned properly
                    />
                    <YAxis reversed='true' yAxisId="feet" orientation="left" />
                    <YAxis type='number' yAxisId="ATM" orientation="right" domain={[0,6]}/>
                    <ReferenceLine yAxisId='ATM' y={1} stroke="red" strokeDasharray="3 3" />
                    {/* <Tooltip /> */}
                    <Legend />
                    {Object.keys(userSettings.linesConfig).map(key => { // loop through all the keys in linesConfig
                        const lineConfig = userSettings.linesConfig[key];
                        if (!key.startsWith('c')) {
                            if (lineConfig.visible) {
                                return (
                                    <Line
                                        key={key}
                                        type="monotone"
                                        dataKey={key}
                                        stroke={lineConfig.stroke}
                                        yAxisId={lineConfig.yAxisId}
                                        name={key}
                                        dot={false}
                                    />
                                );
                            } else {  
                                return null;
                            }
                        } else {
                            return Object.keys(lineConfig).map(key2 => {
                                const cLineConfig = userSettings.linesConfig[key][key2];
                                if (cLineConfig.visible) {
                                    return (
                                        <Line
                                            key={`${key}-${key2}`}
                                            type="monotone"
                                            dataKey={(data) => data[key]?.[key2]}
                                            stroke={cLineConfig.stroke}
                                            yAxisId={cLineConfig.yAxisId}
                                            name={`${key}-${key2}`}
                                            dot={false}
                                        />
                                    );
                                } else {  
                                    return null;
                                }

                            })
                        }
                        
                    })}
                </LineChart>
                </ResponsiveContainer>
                <VerticalSlider
                    min={userSettings.maxDepth*-1}
                    max="0"
                    value={depth*-1}
                    onChange={handleDepthChange}
                    height="100%" // or any specific height you want
                />
                {depth}
                
            </div>
            <div className='control-buttons'>
                <button onClick={handleTogglePlay}>
                    { isPlaying ? "Pause" : "Play" }
                </button>
                <button onClick={handleResetGraph}>
                    RESET
                </button>
            </div>
        </div>
    );
}

export default Plot;