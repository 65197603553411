import { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context/UserContext";
import { UserSettingsContext } from "../../context/UserSettingsContext";
import "../../App.css";


const SettingsPane = () => {

    // hooks
    const { userInfo } = useContext(UserContext);
    const { userSettings, updateUserSettings } = useContext(UserSettingsContext);
    const [ chartUpdateIntervalMS, setChartUpdateIntervalMS ] = useState(100);
    const [ gasPercentHelium, setGasPercentHelium ] = useState(0);
    const [ gasPercentNitrogen, setGasPercentNitrogen ] = useState(79);
    const [ gasPercentOxygen, setGasPercentOxygen ] = useState(21);
    const [ simulatedTimeStep, setSimulatedTimeStep ] = useState(2);
    const [ maxDepth, setMaxDepth ] = useState(130);
    const [ plotType, setPlotType ] = useState('tp');

    const onSubmit = async (e) => {
        e.preventDefault();

        if (!userInfo.userId) {
            console.error("No userID");
        }

        try {
            updateUserSettings(userInfo.userId, {
                maxDepth: maxDepth,
                chartUpdateIntervalMS: parseFloat(chartUpdateIntervalMS),
                gasPercentHelium: parseFloat(gasPercentHelium),
                gasPercentNitrogen: parseFloat(gasPercentNitrogen),
                gasPercentOxygen: parseFloat(gasPercentOxygen),
                simulatedTimeStep: parseFloat(simulatedTimeStep),
                plotType: plotType
            });
        } catch (error) {
            console.error("Failed to update settings:", error);
        }
        
    };
    
    return (
    <div className="settings-pane">
        <h3> Settings </h3>
        <form onSubmit={onSubmit}>
            Graph Type
            <div className="radio">
                <label className="radio-label">
                    <input
                        type='radio'
                        value='tp'
                        checked={plotType === 'tp'}
                        onChange={(e) => setPlotType(e.target.value)}
                    />
                    Time-Pressure
                </label>
                <label className="radio-label">
                    <input
                        type='radio'
                        value='pp'
                        checked={plotType === 'pp'}
                        onChange={(e) => setPlotType(e.target.value)}
                    />
                    Pressure-Pressure
                </label>
            </div>
            <label htmlFor="max-depth">Max Depth (ft)
                <input 
                    type="number"
                    name="Max Depth"
                    id="max-depth"
                    placeholder={userSettings.maxDepth}
                    onChange={(e) => setMaxDepth(e.target.value)}
                />
            </label>
            <label htmlFor="update-interval">Chart Update Interval (ms)
                <input 
                    type="number"
                    name="Update Interval"
                    id="update-interval"
                    placeholder={userSettings.chartUpdateIntervalMS}
                    onChange={(e) => setChartUpdateIntervalMS(e.target.value)}
                />
            </label>
            <label>Helium Gas Percent
                <input
                    type="number"
                    name="Helium Gas Percent"
                    id="gas-percent-helium"
                    placeholder={userSettings.gasPercentHelium}
                    onChange={(e) => setGasPercentHelium(e.target.value)}
                />
            </label>
            <label>Nitrogen Gas Percent
                <input
                    type="number"
                    name="Nitrogen Gas Percent"
                    id="gas-percent-nitrogen"
                    placeholder={userSettings.gasPercentNitrogen}
                    onChange={(e) => setGasPercentNitrogen(e.target.value)}
                />
            </label>
            <label>Oxygen Gas Percent
                <input
                    type="number"
                    name="Oxygen Gas Percent"
                    id="gas-percent-oxygen"
                    placeholder={userSettings.gasPercentOxygen}
                    onChange={(e) => setGasPercentOxygen(e.target.value)}
                />
            </label>
            <label>Simulated Time Step (s)
                <input
                    type="number"
                    name="Simulated Time Step"
                    id="simulated-time-step"
                    placeholder={userSettings.simulatedTimeStep}
                    onChange={(e) => setSimulatedTimeStep(e.target.value)}
                />
            </label>
            <button type="submit">Save Settings</button>
        </form>
        <h3>Current Settings</h3>
        <ul className="settingsList">
            <li>Max Depth: { userSettings.maxDepth } ft </li>
            <li>Update Interval: { userSettings.chartUpdateIntervalMS } ms </li>
            <li>Gas Percent Helium: { userSettings.gasPercentHelium } %</li>
            <li>Gas Percent Nitrogen: { userSettings.gasPercentNitrogen } %</li>
            <li>Gas Percent Oxygen: { userSettings.gasPercentOxygen } %</li>
            <li>Simulated Time Step: { userSettings.simulatedTimeStep } s</li>
            {/* <li>Lines Config: {userSettings.linesConfig } </li> */}
        </ul>
    </div>
    );
}

export default SettingsPane;