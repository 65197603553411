import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { UserProvider } from './context/UserContext';
import { UserSettingsProvider } from './context/UserSettingsContext';
import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
    applicationId: 'cc7999b3-2d70-49ab-92e0-55e4d605a29e',
    clientToken: 'pube7a98e4d610d2263501ad1a28360fecb',
    site: 'datadoghq.com',
    service: 'decogame',
    env: 'prod',
    // Specify a version number to identify the deployed version of your application in Datadog 
    // version: '1.0.0', 
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <UserProvider>
      <UserSettingsProvider>
        <App />
      </UserSettingsProvider>
    </UserProvider>
  </React.StrictMode>
);

