import "../../App.css";
import "./ExtraInfoWidget.css"

const ExtraInfoWidget = ({ extraInfo }) => {
    // console.log("got extraInfo: ", extraInfo);
    const widgetClassName = extraInfo.dangerZone ? 'danger' : null ;
    const diveMaxGFClassName = extraInfo.diveMaxGradientFactor>100 ? 'danger' : null ;

    const getDateFormat = (diveTime) => {
        if(diveTime === undefined){
            return "";
        }
        else {
            return new Date(extraInfo.diveTime * 1000).toISOString().substr(11, 8);
        }
    }

    return (
    <div>
        <h3> Extra Info </h3>
        <div className={`extra-info-widget ${widgetClassName}`}>
            <p className="depth">Depth: {extraInfo.depth} ft</p>
            <p className={`gradient-factor ${widgetClassName}`}>Current Gradient Factor: {Math.round(extraInfo.maxGradientFactor)} %</p>
            <p className={`dive-max-gf ${diveMaxGFClassName}`}>Dive Max GF: {Math.round(extraInfo.diveMaxGradientFactor)} %</p>
            <p className="time">Time: {getDateFormat(extraInfo.diveTime)} </p>
        </div>
    </div>
    );
}

export default ExtraInfoWidget;