import React, { createContext, useState, useEffect } from 'react';

// Create a Context for the user data
export const UserContext = createContext(null);

// Create a Provider Component
export const UserProvider = ({ children }) => {
    const [userInfo, setUserInfo] = useState({
        userEmail: '',
        userId: '',
        isAuth: false,
        token: ''
    });

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        // Fetch the user info from localStorage
        const authInfo = localStorage.getItem('auth');
        const token = localStorage.getItem('token');

        if (authInfo && token) {
            const parsedInfo = JSON.parse(authInfo);
            setUserInfo({
                ...parsedInfo,
                token,
                isAuth: true
            });
        }
    }, []); // The empty array ensures this effect only runs once after mount

    const value = { userInfo, setUserInfo };

    return (
        <UserContext.Provider value={ value }>
            {children}
        </UserContext.Provider>
    );
};
