import { signOut } from "firebase/auth";
import { auth } from "../../config/firebase-config";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Plot from "../../components/plot/Plot";
import PlotPP from "../../components/plot/PlotPP";
import SettingsPane from "../../components/settings-pane/SettingsPane";
import SettingsPanePP from "../../components/settings-pane/SettingsPanePP";
import "../../App.css";
import { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import ExtraInfoWidget from "../../components/extra-info-widget/ExtraInfoWidget";
import { UserSettingsContext } from "../../context/UserSettingsContext";

export const DecoGame = () => {

    const { userInfo, setUserInfo } = useContext(UserContext);
    const userEmail = userInfo.userEmail;
    const { userSettings } = useContext(UserSettingsContext);

    const isAuth = userInfo.isAuth;
    const navigate = useNavigate();

    if (!isAuth) {
        return <div>
            <p>You're not signed in.</p>
            <Link to="/">Go home.</Link>
        </div>
    }

    const handleSignOut = async () => {
        try {
            await signOut(auth);
            setUserInfo({
                userId: "",
                userEmail: "",
                isAuth: false,
                token: ""
            });
            localStorage.removeItem('token');
            localStorage.removeItem('auth');
            navigate("/");
        } catch (err) {
            console.error(err);
        }
    }

    const getPlotComponent = plotType => {
        switch (plotType) {
            case 'tp' :
                return <Plot />;
            case 'pp' :
                return <PlotPP />;
            default :
                return null;
        }
    };

    const getSettingsComponent = plotType => {
        switch (plotType) {
            case 'tp' :
                return <SettingsPane />;
            case 'pp' :
                return <SettingsPanePP />;
            default :
                return null;
        }
    }

    return (
        <div>
            <div className="game-header">
                {/* <ExtraInfoWidget/> */}
                <div className="user-info" style={{"flex": "1 1"}}>
                    <p>Email: {userEmail} </p>
                    <h3>Welcome to DecoGame!</h3>
                </div>
            </div>
            <div className="game-and-settings-area" style={{ display: 'flex', alignItems: 'stretch' }}>
                { getPlotComponent(userSettings.plotType) }
                { getSettingsComponent(userSettings.plotType) }
                {/* <SettingsPane /> */}
            </div>
            <div>
                <button onClick={handleSignOut}>Sign Out</button>
            </div>
        </div>
    )
}