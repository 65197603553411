import { createUserWithEmailAndPassword } from "firebase/auth";
import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../../config/firebase-config";
import { UserContext } from "../../context/UserContext";
import { UserSettingsContext } from "../../context/UserSettingsContext";
import { DEFAULT_USER_SETTINGS } from "../../hooks/default_user_settings";


export const SignUp = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const { updateUserSettings } = useContext(UserSettingsContext);
    const { setUserInfo } = useContext(UserContext);
    const navigate = useNavigate();

    const handleSignUpSuccess = async (user) => {
        updateUserSettings(user.uid, DEFAULT_USER_SETTINGS);
        navigate("/deco-game");
    }

    // handle submission of the form
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("in handleSubmit");
        try {
            const userCredential = await createUserWithEmailAndPassword(
                auth,
                email,
                password
            );
            const user = userCredential.user;
            const authInfo = {
                userId: user.uid,
                userEmail: user.email,
                isAuth: true
            };
            const accessToken = await user.getIdToken();
            localStorage.setItem('token', accessToken); // why refresh instead of access?
            localStorage.setItem('auth', JSON.stringify(authInfo));

            setUserInfo({
                userId: user.uid,
                userEmail: user.email,
                isAuth: true,
                token: accessToken
            });

            handleSignUpSuccess(user);
        } catch (err) {
            console.error(err);
        }
    }



    return (
        <div>
            <h3> Register New User </h3>
            <form onSubmit={handleSubmit} className='signup-form'>
                <input
                    type="email"
                    placeholder="Your Email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <br />
                <input
                    type="password"
                    placeholder="password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <br /><br />
                <button type="submit" className='signup-button'>Sign Up!</button>
            </form>
            <p>Go to <Link to="/">Login Page</Link></p>
        </div>
    );
}