import { signInWithEmailAndPassword } from "firebase/auth";
import { useContext, useState } from "react";
import { Link, useNavigate, Navigate } from "react-router-dom";
import { auth, db } from "../../config/firebase-config";
import { UserContext } from "../../context/UserContext";
import { doc, onSnapshot } from "firebase/firestore";
import { UserSettingsContext } from "../../context/UserSettingsContext";
import { DEFAULT_USER_SETTINGS } from "../../hooks/default_user_settings";

export const Auth = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const { userInfo, setUserInfo } = useContext(UserContext);
    const { updateUserSettings } = useContext(UserSettingsContext);
    

    if (userInfo.isAuth) {
        return <Navigate to="/deco-game" />;
    }

    // const loadUserSettings = (userId) => {
    //     const userSettingsDocRef = doc(db, "user-configs", userId);
    //     const unsubscribe = onSnapshot(userSettingsDocRef, (doc) => {
    //         if (doc.exists()) {
    //             updateUserSettings(doc.data());
    //         } else {
    //             // Document does not exist - could be a new user, set default settings
    //             updateUserSettings(DEFAULT_USER_SETTINGS);
    //         }
    //     }, (error) => {
    //         console.error("Error loading user settings on login:", error);
    //         // Handle any errors
    //     });
        
    //       // Make sure to unsubscribe from the listener when the component unmounts
    //       return unsubscribe;
        
    // }

    // handle submission of the form
    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log("in auth handleSubmit");
        try {
            const userCredential = await signInWithEmailAndPassword(
                auth,
                email,
                password
            );
            const user = userCredential.user;
            const authInfo = {
                userId: user.uid,
                userEmail: user.email,
                isAuth: true
            };

            setUserInfo({
                userId: user.uid,
                userEmail: user.email,
                isAuth: true,
                token: user.accessToken
            });

            localStorage.setItem('token', user.accessToken);
            localStorage.setItem('auth', JSON.stringify(authInfo));

            navigate("/deco-game");
        } catch (err) {
            console.error(err);
        }
    }



    return (
        <div>
            <h3> Sign In </h3>
            <form onSubmit={handleSubmit} className='login-form'>
                <input
                    type="email"
                    placeholder="Your Email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <br />  {/*<br />*/}
                <input
                    type="password"
                    placeholder="password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <br /><br />
                <button type="submit" className='login-button'>Log In</button>
            </form>
            <p>New? <Link to="/sign-up">Create an Account</Link></p>
        </div>
    );
}