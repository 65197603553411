import { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context/UserContext";
import { UserSettingsContext } from "../../context/UserSettingsContext";
import "../../App.css";


const SettingsPanePP = () => {

    // hooks
    const { userInfo } = useContext(UserContext);
    const { userSettings, updateUserSettings } = useContext(UserSettingsContext);
    const chartUpdateIntervalMS = 100;
    const [ simulatedTimeStep, setSimulatedTimeStep ] = useState(5);
    const [ plotType, setPlotType ] = useState('pp');
    const maxDepth = 200;
    const gasPercentNitrogen = 100;
    const gasPercentHelium = 0;
    const gasPercentOxygen = 0;


    const onSubmit = async (e) => {
        e.preventDefault();

        if (!userInfo.userId) {
            console.error("No userID");
        }

        try {
            updateUserSettings(userInfo.userId, {
                simulatedTimeStep: parseFloat(simulatedTimeStep),
                plotType: plotType
            });
        } catch (error) {
            console.error("Failed to update settings:", error);
        }
        
    };

    useEffect(() => {
        if (!userInfo.userId) {
            console.error("No userID");
        }

        try {
            updateUserSettings(userInfo.userId, {
                maxDepth: maxDepth,
                chartUpdateIntervalMS: parseFloat(chartUpdateIntervalMS),
                gasPercentHelium: parseFloat(gasPercentHelium),
                gasPercentNitrogen: parseFloat(gasPercentNitrogen),
                gasPercentOxygen: parseFloat(gasPercentOxygen),
                simulatedTimeStep: parseFloat(simulatedTimeStep),
                plotType: plotType
            });
        } catch (error) {
            console.error("Failed to update settings:", error);
        }    
    }, []); 
    
    return (
    <div className="settings-pane">
        <h3> Settings </h3>
        <form onSubmit={onSubmit}>
            Graph Type
            <div className="radio">
                <label className="radio-label">
                    <input
                        type='radio'
                        value='tp'
                        checked={plotType === 'tp'}
                        onChange={(e) => setPlotType(e.target.value)}
                    />
                    Time-Pressure
                </label>
                <label className="radio-label">
                    <input
                        type='radio'
                        value='pp'
                        checked={plotType === 'pp'}
                        onChange={(e) => setPlotType(e.target.value)}
                    />
                    Pressure-Pressure
                </label>
            </div>
            <label>Simulated Time Step (s)
                <input
                    type="number"
                    name="Simulated Time Step"
                    id="simulated-time-step"
                    placeholder={userSettings.simulatedTimeStep}
                    onChange={(e) => setSimulatedTimeStep(e.target.value)}
                />
            </label>
            <button type="submit">Save Settings</button>
        </form>
        <h3>Current Settings</h3>
        <ul className="settingsList">
            <li>Max Depth: { userSettings.maxDepth } ft </li>
            <li>Update Interval: { userSettings.chartUpdateIntervalMS } ms </li>
            <li>Gas Percent Helium: { userSettings.gasPercentHelium } %</li>
            <li>Gas Percent Nitrogen: { userSettings.gasPercentNitrogen } %</li>
            <li>Gas Percent Oxygen: { userSettings.gasPercentOxygen } %</li>
            <li>Simulated Time Step: { userSettings.simulatedTimeStep } s</li>
            {/* <li>Lines Config: {userSettings.linesConfig } </li> */}
        </ul>
    </div>
    );
}

export default SettingsPanePP;